@import "modules/root/styles/planr/planr-scrolls.scss";
@import "modules/common/components/variables.scss";
@import "modules/root/styles/planr/variables.scss";
.employee {
  overflow-y: auto;

  padding: 12px;
  width: 396px;
  // @include planr-scoll();
}

.stripe {
  cursor: pointer;

  + .stripe {
    margin-top: 8px;
  }
}

.name {
  width: 100%;
  height: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .nameValue {
    display: flex;
    align-items: center;
    .workData {
      margin-left: 5px;
      color: #fff;
      display: block;
      height: 20px;
      width: 20px;
      line-height: 20px;
      text-align: center;
      border-radius: 50%;
      font-size: 10px;
      background-color: $planr-lightblue-color;
    }

    .expData {
      margin-left: 5px;
      color: #fff;
      height: 20px;
      width: 20px;
      line-height: 20px;
      text-align: center;
      display: block;
      border-radius: 50%;
      font-size: 10px;
      background-color: $planr-red-color;
    }
  }
  .value {
    font-weight: bold;

    text-align: right;
    font-size: 15px;
  }
}

.percents {
  margin-top: 6px;
  width: 100%;
  background-color: rgba(0, 39, 61, 0.05);

  > div {
    background-color: $standard-planr-darkblue;
    height: 4px;
  }
}
