@import "modules/common/components/variables.scss";
@import "modules/root/styles/planr/variables.scss";

.grid {
  display: flex;
  flex: 1 1;
  overflow: hidden;
  flex-direction: column;
  padding-right: $standard-padding;

  :global {
    .fired .ag-cell-value {
      color: rgba($color: $standard-planr-darkblue, $alpha: 0.5);
    }
  }
}

.hidden {
  display: none;
}

.questions {
  display: flex;
  align-items: center;
  .workData {
    cursor: pointer;
    margin-left: 5px;
    color: #fff;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    font-size: 10px;
    background-color: $planr-lightblue-color;
  }

  .expData {
    cursor: pointer;
    margin-left: 5px;
    color: #fff;
    height: 20px;
    width: 20px;
    line-height: 20px;
    text-align: center;
    display: block;
    border-radius: 50%;
    font-size: 10px;
    background-color: $planr-red-color;
  }
}
